import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servername } from '../strings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn: boolean;
  private admin: boolean;
  private staff: boolean;
  private user: boolean;
  private failure: boolean;
  private userid: number = undefined;
  private authToken: string;
private therapist :boolean
  constructor(private http: HttpClient) {
    this.loggedIn = false;
  }

  get AuthToken() {
    return this.authToken;
  }

  async login(values): Promise<boolean> {
    console.log(values);


    try {

      this.loggedIn = false;
      this.admin = false;
      this.staff = false;
      this.user = false;
      this.failure = false;
      let result: any = await this.http.post(`${servername}/signin`, { ...values }).toPromise();
      this.authToken = result.token;
      localStorage.setItem('token', this.AuthToken);
      this.parseToken(this.AuthToken);

    } catch (err) {
      console.log(err);

      this.failure = true;
      this.loggedIn = false;
      this.admin = false;
      this.staff = false;
      this.user = false;
    }
    return this.loggedIn;
  }

  parseToken(token) {
    const tokens = token.split(".");
    const userData: any = JSON.parse(atob(tokens[1]));
    this.loggedIn = true;
    console.log(userData);
    if (userData.type === 'A')
      this.admin = true;
    else if (userData.type === 'S')
      this.staff = true;
    else if (userData.type === 'P')
      this.therapist = true;

    this.userid = userData.uid;
  }

  async logout(): Promise<boolean> {
    localStorage.clear();
    this.loggedIn = false;
    return this.loggedIn;
  }

  isLoggedIn(): boolean {
    if (this.userid !== undefined)
      return this.loggedIn;

    const token = localStorage.getItem('token')
    if (!token)
      return false;

    this.authToken = token;
    this.parseToken(this.AuthToken);
    return this.loggedIn;
  }

  isAdmin(): boolean {
    return this.admin;
  }

  isStaff(): boolean {
    return this.staff;
  }
  isTherapist(): boolean {
    return this.therapist;
  }

  isUser(): boolean {
    return this.user;
  }

  isFailed(): boolean {
    return this.failure;
  }

  userId(): number {
    return this.userid;
  }
}
