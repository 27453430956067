import { SelectItem, MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/core/services/form.service';
import { BranchService } from 'src/app/core/services/branch.service';
import { UserService } from 'src/app/core/services/user.service';
import { DatePipe } from '@angular/common';
import { UploadService } from 'src/app/core/services/upload.service';

interface Branches {
  name: string;
  code: string;
  id: number;
}

@Component({
  selector: 'app-staffpatient-form',
  templateUrl: './staffpatient-form.component.html',
  styleUrls: ['./staffpatient-form.component.scss']
})
export class StaffpatientFormComponent implements OnInit {

  public forms;
  public Forms;
  public date;
  public date1;
  public branch;
  public branches: SelectItem[];
  public selectBranch: Branches;
  public branchid;
  public formFetching;
  public therapistData;
  first = 0;
  rows = 10;
  therapists: any;
  allTherapists: any;
  edit = false
  constructor(private formService: FormService,
    private datePipe: DatePipe,
    private userService: UserService,
    private messageService: MessageService,
    private branchService: BranchService,
    private uploadService: UploadService) {
    this.forms = [];
    this.branches = [];
    this.date = new Date();
    this.formFetching = false;
  }

  async ngOnInit() {
    this.allTherapists = await this.userService.getphisiolist()
    console.log(this.allTherapists);

  }

  async onSubmit() {
    this.formFetching = true;
    this.date1 = this.datePipe.transform(this.date, "yyyy-MM-dd");
    const forms = await this.formService.getFormsOnlyByDate(this.date1);
    this.fetchForms(forms);
  }
  async assign(event: any, formid: any, frm: any) {
    console.log(event.target.value);
    const data = {
      userid: event.target.value,
      formid: formid,
      frm: frm
    }
    this.therapistData = data;

    // const success = await this.formService.addtherapist(data)
    // if (success == "ok") {
    //   this.onSubmit()
    //   document.getElementById('cancel').click()
    //   return this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Therapist Assigned !' });
    // }
    // return this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Therapist Not Assigned !' });

  }

  async fetchForms(formValue) {
    let tempForms = [];
    for (let i = 0; i < formValue.length; i++) {
      const element = formValue[i];

      //Get Branch Name
      const br: any = await this.branchService.getBranchByBranchId(element.branchid);
      element.branchName = br.name;

      //Get Uploaded forms
      const uploadedForms = await this.formService.getFormData(element.formid);
      element.uploadedForms = uploadedForms;
      tempForms.push(element);
    }
    this.forms = tempForms;
    this.formFetching = false;
    console.log(this.forms);

  }

  async onRowEditSave(form) {
    this.edit = false
    const body = {
      updates: {
        name: form.name,
        dob: this.datePipe.transform(form.dob, "yyyy-MM-dd"),
        tokenNo: form.tokenNo
      },
      formid: form.formid
    }

    try {
      await this.formService.updateForms(body);
    } catch {

    }
    if(this.therapistData) {
      const data = {
        userid: this.therapistData.userid,
        formid: this.therapistData.formid
      }
      await this.formService.addtherapist(data);
      this.therapistData.frm.therapist = this.therapistData.userid
    }
    this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Form Updated!!!' });
  }
  async editbtn(form: any) {
    this.edit = true
    await this.processTherapist(form.branchid)

  }
  canceledit() {
    this.edit = false
  }
  async onDownload(uploadedForm) {
    const url = await this.uploadService.getLinkForFile(uploadedForm.path);
    window.location.href = url;
  }
  async processTherapist(bid: any) {
    let obj = []
    for (let i = 0; i < this.allTherapists.length; i++) {
      const element = this.allTherapists[i];
      if (element.branchid == bid && element.state == 1) {
        obj.push(element)
      }
    }
    this.therapists = obj
    console.log(this.therapists);
  }
}
