import { Component, OnInit } from '@angular/core';
import { BranchService } from 'src/app/core/services/branch.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { FormService } from 'src/app/core/services/form.service';

@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.scss']
})
export class CreateBranchComponent implements OnInit {

  public branchforms;
  public forms;
  public form1;
  public form2;

  constructor(private branchService: BranchService,
    private formService: FormService,
    private messageService: MessageService,
    private router: Router) {
    this.forms = [];
  }

  async ngOnInit() {
    this.branchforms = await this.formService.getBranchForms();
    this.processBranchForms(this.branchforms);
  }

  processBranchForms(branchforms) {
    for (let i = 0; i < branchforms.length; i++) {
      const element = branchforms[i];
      this.forms.push({
        label: element.name,
        value: {
          id: element.formid,
          name: element.name,
        }
      })
    }
  }

  async onSubmit(formvalues) {
    if (this.form1 === this.form2) {
      this.messageService.add({ severity: 'failure', summary: 'PeaceRiver', detail: 'Form1 and Form2 should be different!!!' });
    } else {
      const formParams = {
        name: formvalues.value.name,
        brid: formvalues.value.brid,
        address: formvalues.value.address,
        contactno: formvalues.value.contactno,
        zip: formvalues.value.zip,
        branchprefix: formvalues.value.branchprefix,
      }
      const branch: any = await this.branchService.createBranch(formParams);

      if (this.form1) {
        const form1Values = {
          branchid: branch.branchid,
          formid: formvalues.value.form1.id,
          orderid: 1
        }
        await this.branchService.createBranchForms(form1Values);
      }

      if (this.form2) {
        const form2Value = {
          branchid: branch.branchid,
          formid: formvalues.value.form2.id,
          orderid: 2
        }
        await this.branchService.createBranchForms(form2Value);
      }

      this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Branch Created!!!' });
      this.router.navigateByUrl('/admin/mainadmin/branch-userlist');
    }
  }
}
