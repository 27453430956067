import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { S3Service } from './s3.service';
import imageCompression from 'browser-image-compression';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient, private s3: S3Service) { }

  async compressImage(imageFile) {

    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      return imageFile;
    }
  }

  async uploadFileToS3(file) {
    const contentType: string = file.type;
    if (contentType.includes('image'))
      file = await this.compressImage(file);

    const fileuploadurl: any = await this.s3.getUrlForNewObject(contentType);
    const headers = new HttpHeaders({
      'content-type': contentType,
      'x-amz-acl': 'public-read',
      'Content-Disposition': `attachment; filename=\"${file.name}\"`,
      'Access-Control-Allow-Origin': '*'
    });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl.url,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    await this.http.request(req).toPromise();
    let fileMeta = {
      type: file.type,
      name: file.name,
      path: fileuploadurl.key,
      size: file.size
    }
    return fileMeta;
  }

  async getLinkForFile(key) {
    const fileuploadurl: any = await this.s3.getUrlForView(key);
    return fileuploadurl.url;
  }
}