import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public submitted = false;

  constructor(private router: Router,
    public authService: AuthService,
    public translate: TranslateService,
    private messageService: MessageService) { }

  ngOnInit() {
    document.body.classList.remove('bg-img');
    if (this.authService.isLoggedIn()) {
      if (this.authService.isAdmin())
        this.router.navigateByUrl('/admin/dashboard/admindashboard');
        else if (this.authService.isStaff())
        this.router.navigateByUrl('/staff/dashboard/staffdashboard');
        else if (this.authService.isTherapist())
        this.router.navigateByUrl('/staff/patient/therapist-form');
      }
  }

  async onSubmit(values) {
    this.submitted = true;
    const loggedIn: boolean = await this.authService.login(values);
    console.log(loggedIn);


    if (loggedIn === false) {
      this.submitted = false;
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Login Failed. Incorrect Username or Password!' });
    }

    if (this.authService.isUser()) {
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Login Failed. Authentication Error!' });
    }

    if (loggedIn && this.authService.isAdmin())
      return this.router.navigateByUrl('/admin/dashboard/admindashboard');

    if (loggedIn && this.authService.isStaff()) {
      return this.router.navigateByUrl('/staff/dashboard/staffdashboard');
    }
    if (loggedIn && this.authService.isTherapist()) {
      return this.router.navigateByUrl('/staff/patient/therapist-form');
    }
  }

}
