import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { BranchService } from 'src/app/core/services/branch.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public usertype;
  public types;
  public branch;
  public branches;
  public state;
  public brValue;
  public hash;
  public password;
  public value;
  public brid = 'Disabled';
  disabled: boolean = true;

  constructor(private userService: UserService,
    private branchService: BranchService,
    private messageService: MessageService,
    private router: Router) {
    this.state = [
      { label: 'Suspended', value: { id: 0, name: 'Suspended', code: 'S' } },
      { label: 'Active', value: { id: 1, name: 'Active', code: 'A' } }
    ]
    this.types = [];
    this.branches = [];
  }

  async ngOnInit() {
    this.usertype = await this.userService.userTypes();
    this.processUsertypes(this.usertype);
    this.branch = await this.branchService.getBranch();
    this.processBranches(this.branch);
    console.log(this.usertype);

  }

  processUsertypes(usertype) {
    for (let i = 0; i < usertype.length; i++) {
      const element = usertype[i];
      let x= ""
      if (element.type == "A"){
         x = "admin"
      }
      if (element.type == "S"){
         x = "Branch Admin"
      }
      if (element.type == "U"){
         x = "Staff"
      }
      if (element.type == "P"){
         x = "Phisiotherapist"
      }
      this.types.push({
        label: x,
        value: {
          id: element.typeid,
          name: element.type
        }
      })
    }
  }

  processBranches(branch) {
    for (let i = 0; i < branch.length; i++) {
      const element = branch[i];
      this.branches.push({
        label: element.name,
        value: {
          id: element.branchid,
          name: element.name,
          brid: element.brid
        }
      })

    }
  }

  onChange(event) {
    this.brid = event.brid;
  }

  async onSubmit(formvalues) {
    if (this.hash == this.password) {
      const formParams = {
        username: formvalues.value.username,
        fname: formvalues.value.fname,
        lname: formvalues.value.lname,
        emailid: formvalues.value.emailid,
        phone: formvalues.value.phone,
        hash: formvalues.value.hash,
        state: formvalues.value.state.id,
        branchid: formvalues.value.branchid.id,
        typeid: formvalues.value.typeid.id
      }
      await this.userService.createUser(formParams);
      this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'User Created!!!' });
      this.router.navigateByUrl('/admin/mainadmin/branch-userlist');
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Confirm Password Mismatch' });
    }

  }


}
