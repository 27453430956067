import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  DashboardComponent, AdminComponent, MainadminComponent, PatientComponent
} from './pages';

import { LoginGuard } from '../core/guards/login-guard';
import { AdminGuard } from '../core/guards/admin-guard';
import { CreateUserComponent, CreateBranchComponent, BranchUserlistComponent, ChangePasswordUserComponent, ChangePasswordComponent } from './admin_comps';
import { PatientFormComponent } from './patient_comps';
import { PatientChartComponent } from './dashboard_comps';

const routes: Routes = [
  {
    path: 'admin', component: AdminComponent, canActivate: [LoginGuard, AdminGuard],
    children: [
      {
        path: 'dashboard', component: DashboardComponent,
        children: [
          { path: 'admindashboard', component: PatientChartComponent }
        ]
      },
      {
        path: 'mainadmin', component: MainadminComponent,
        children: [
          { path: 'create-branch', component: CreateBranchComponent },
          { path: 'create-user', component: CreateUserComponent },
          { path: 'branch-userlist', component: BranchUserlistComponent },
          { path: 'change-password', component: ChangePasswordComponent },
          { path: 'change-password-user/:userid/:username', component: ChangePasswordUserComponent }
        ]
      },
      {
        path: 'patient', component: PatientComponent,
        children: [
          { path: 'patient-form', component: PatientFormComponent },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    LoginGuard,
    AdminGuard
  ]
})
export class AdminRoutingModule { }