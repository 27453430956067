import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { servername } from '../strings';
import { ReqCache } from './req-cache';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends ReqCache {

  constructor(private http: HttpClient,
    private auth: AuthService) {
    super();
  }

  public async addReport(reportDetails) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return await this.http.post(`${servername}/report`, { ...reportDetails }, { headers: headers }).toPromise();
  }

  getReportByReportID(reportid) {
    const url: string = `${servername}/report?reportid=${reportid}`;
    const cached = this.checkCache(url);
    if (!!cached) {
      return cached;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    const res = this.http.get(url, { headers: headers }).toPromise();
    return this.cache(url, res);
  }

  public async addDependantReport(report) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    const result1: any = await this.http.put(`${servername}/report`, { ...report }, { headers: headers }).toPromise();
  }

  public async getDependentReport(depid, deptype) {
    const url: string = `${servername}/report?depid=${depid}&deptype=${deptype}`;
    // const cached = this.checkCache(url);
    // if (!!cached) {
    //   return cached;
    // }
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(url, { headers: headers }).toPromise();
    // return this.cache(url, res);
  }

  public async deleteReport(reportid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.delete(`${servername}/report?reportid=${reportid}`, { headers: headers }).toPromise();
  }
}