<div class="changepw p-col-10 p-md-5 p-lg-8 p-xl-8">
    <div class="heading p-component">
        Change Admin Password
    </div>
    <form #fPassword="ngForm" (ngSubmit)="onSubmit(fPassword.value)">
        <div class="p-component p-field p-fluid p-formgrid p-grid p-col-10">
            <span class="p-float-label p-field p-col-10 p-md-6">
                <input required [(ngModel)]="oldpw" id="opw" name="oldpw" type="password" pInputText>
                <label for="opw">Old Password</label>
            </span>
        </div>
        <div class="p-component p-fluid p-formgrid p-grid p-col-10">
            <span class="p-float-label p-field p-col-10 p-md-6">
                <input pPassword required [(ngModel)]="newpw" id="npw" name="newpw" type="password">
                <label for="npw">New Password</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-6">
                <input pPassword required [(ngModel)]="password" id="pw" name="password" type="password">
                <label for="pw">Confirm Password</label>
            </span>
        </div>

        <span class="p-float-label p-field p-col-10 p-md-6">
            <button pButton type="submit" label="Reset Password" [disabled]="!fPassword.valid"></button>
        </span>

    </form>
</div>