<div class="user p-col-10 p-md-6 p-lg-8 p-xl-8">
    <div class="p-component">
        Create New User
    </div> 
    <form #fUser="ngForm" (ngSubmit)="onSubmit(fUser)">
        <div class="p-component p-fluid p-formgrid p-grid p-col-10">
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="fname" name="fname" type="text" pInputText>
                <label for="fname">First Name</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="lname" name="lname" type="text" pInputText>
                <label class="element" for="lname">LastName</label>
            </span>

            <div class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="emailid" name="emailid" type="text" pInputText>
                <label for="emailid">E-mail Id</label>
            </div>
            <div class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="phone" name="phone" type="text" pInputText>
                <label for="phone">Phone</label>
            </div>


            <div class="p-float-label p-field p-col-10 p-md-5">
                <p-dropdown name="branchid" [options]="branches" ngModel placeholder="Select Branch"
                    (ngModelChange)="onChange($event)"></p-dropdown>
            </div>
            <div class="p-float-label p-field p-col-10 p-md-5">
                <input ngModel name="brid" id="disabled-input" [disabled]="disabled" type="text" pInputText
                    value="{{brid}}">
                    <label for="disabled-input">Branch ID</label>
            </div>

            <div class="p-float-label p-field p-col-10 p-md-5">
                <label class="element" for="usertype">Role</label>
                <p-dropdown id="usertype" name="typeid" [options]="types" ngModel placeholder="Select a Role">
                </p-dropdown>
            </div>
            <div class="p-float-label p-field p-col-10 p-md-5">
                <label class="element" for="float-input">Status</label>
                <p-dropdown [options]="state" name="state" ngModel placeholder="Select Status"></p-dropdown>
            </div>

            <div class="p-float-label p-field p-col-10 p-md-4">
                <input required ngModel id="username" name="username" type="text" pInputText>
                <label for="username">User Name</label>
            </div>
            <div class="p-float-label p-field p-col-10 p-md-3">
                <input pPassword required [(ngModel)]="hash" id="hash" name="hash" type="password" pInputText>
                <label for="hash">Password</label>
            </div>
            <div class="p-float-label p-field p-col-10 p-md-3">
                <input required [(ngModel)]="password" id="password" name="password" type="password" pInputText>
                <label for="password">Confirm Password</label>
            </div>

            <div class="p-field p-col-10 p-sm-8 p-md-6 p-lg-4 p-xl-4">
                <button class="element" pButton type="submit" label="Submit" [disabled]="!fUser.valid"></button>
            </div>
        </div>
    </form>
</div>