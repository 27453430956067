<div class="item-main-page">
    <div class="item-main-page__menu">
        <div class="p-field p-grid p-justify-start">
            <div class="item-main-page__list p-col-fixed">
                <a class="menu-item" routerLink="stafffpatient-form"></a>
            </div>
        </div>
    </div>
    <div class="item-main-page__page">
        <router-outlet></router-outlet>
    </div>
</div>