<div class="p-justify-center">
    <div class="chartValues p-component">
        <div class="p-grid p-justify-center">
            <div class="p-col-5 p-md-4 p-lg-3 p-xl-2">
                <div class="p-inputgroup p-fluid">
                    <div class="p-inputgroup">
                        <span class="p-component p-inputgroup-addon">From</span>
                        <p-calendar [(ngModel)]="date1" name="date1" [showIcon]="true" [style]="{'height':'100%'}">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="p-col-5 p-md-4 p-lg-3 p-xl-2">
                <div class="p-inputgroup p-fluid">
                    <span class="p-component p-inputgroup-addon">To</span>
                    <p-calendar [(ngModel)]="date2" name="date2" [showIcon]="true" [style]="{'height':'100%'}">
                    </p-calendar>
                </div>
            </div>
            <div class="p-field p-col-5 p-md-4 p-lg-3 p-xl-2 p-fluid">
                <button pButton type="submit" label="Submit" (click)="onSubmit($event)"></button>
            </div>
            <div *ngIf="dataFetching === true">
                <p-progressSpinner class="spinner" strokeWidth="4" fill="#EEEEEE" animationDuration="20s">
                </p-progressSpinner>
            </div>
        </div>
    </div>
    <div class="p-grid p-justify-center">
        <div class="p-col-12 p-lg-10 p-xl-8">
            <p-chart type="bar" [data]="data"></p-chart>
        </div>
    </div>
</div>