import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormService } from 'src/app/core/services/form.service';
import { MenuItem } from 'primeng/api';
import { BranchService } from 'src/app/core/services/branch.service';

@Component({
  selector: 'app-patient-chart',
  templateUrl: './patient-chart.component.html',
  styleUrls: ['./patient-chart.component.scss']
})
export class PatientChartComponent implements OnInit {

  public date;
  public date1;
  public date2;
  public months;
  public data;
  public patients;
  public dataFetching;
  public colors = [];

  constructor(private formService: FormService,
    private branchService: BranchService,
    private datepipe: DatePipe) {
    this.date = new Date();
    this.date1 = new Date();
    this.date1.setDate(this.date1.getDate() - 30);
    this.date2 = new Date();
      this.dataFetching = false;
    this.colors = [
      {
        backgroundColor: '#42A5F5',
        borderColor: '#1E88E5'
      },
      {
        backgroundColor: '#9CCC65',
        borderColor: '#7CB342',
      }
    ]
  }

  async ngOnInit() {
    this.dataFetching  = true;
    await this.processChart();
  }

  async processChart() {
    let d1 = this.datepipe.transform(this.date1, "yyyy-MM-dd");
    let d2 = this.datepipe.transform(this.date2, "yyyy-MM-dd");
    this.patients = await this.formService.getPatientFormsByDate(d1, d2);
    this.fetchForms();
  }

  async onSubmit(event) {
    this.dataFetching  = true;
    await this.processChart();
  }

  async fetchForms() {

    let branches = [];

    for (let index = 0; index < this.patients.length; index++) {
      const element = this.patients[index];
      if (branches.findIndex(x => x === element.branchid) === -1)
        branches.push(element.branchid)
    }

    let dateList = {}
    let labels = {
      dates: [],
      dateLabels: []
    };
    var start = this.date1;
    var end = this.date2;

    var loop = new Date(start);
    let index = 1;
    while (loop <= end) {
      dateList[this.datepipe.transform(loop, "yyyy-MM-dd")] = index;
      labels.dates.push(this.datepipe.transform(loop, "yyyy-MM-dd"));
      labels.dateLabels.push(this.datepipe.transform(loop, "MM-dd"));
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
      index++;
    }

    let datasets = [];
    for (let index = 0; index < branches.length; index++) {
      const element = branches[index];
      const br: any = await this.branchService.getBranchByBranchId(element)
      let branch = {
        branchid: element,
        label: br.name,
        data: new Array(labels.dates.length).fill(0),
        fill: false,
        backgroundColor: this.colors[index % 2].backgroundColor,
        borderColor: this.colors[index % 2].borderColor,
      }

      datasets.push(branch);
    }

    for (let index = 0; index < datasets.length; index++) {
      const dataset = datasets[index];

      for (let index2 = 0; index2 < this.patients.length; index2++) {
        const countData = this.patients[index2];

        if (countData.branchid === dataset.branchid) {
          let date = countData.date.substring(0, 10);
          let dateIndex = dateList[date];
          dataset.data[dateIndex -1] = countData.total_forms;
        }
      }
    }

    this.data = {
      labels: labels.dateLabels,
      datasets: datasets
    }
    this.dataFetching = false;
  }
}
