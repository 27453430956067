<div class="branch p-col-10 p-md-5 p-lg-8 p-xl-8">
    <div class="p-component">
        Create New Branch
    </div>
    <form #fBranch="ngForm" (ngSubmit)="onSubmit(fBranch)">
        <div class="p-component p-fluid p-formgrid p-grid p-col-10">
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="name" name="name" type="text" pInputText>
                <label for="name">Branch Name</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="brid" name="brid" type="text" pInputText>
                <label for="brid">Br ID</label>
            </span>
            <span class="p-float-label p-field p-col-10">
                <textarea required ngModel id="address" name="address" [rows]="5" [cols]="25" pInputTextarea
                    autoResize="autoResize"></textarea>
                <label for="address">New Branch Address</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="contactno" name="contactno" type="text" pInputText>
                <label for="contactno">Contact No</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="zip" name="zip" type="number" pInputText>
                <label for="zip">ZipCode</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <input required ngModel id="branchprefix" name="branchprefix" type="text" pInputText>
                <label for="branchprefix">Branch Prefix</label>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <p-dropdown [(ngModel)]="form1" name="form1" [options]="forms" placeholder="Select Form1"></p-dropdown>
            </span>
            <span class="p-float-label p-field p-col-10 p-md-5">
                <p-dropdown [(ngModel)]="form2" name="form2" [options]="forms" placeholder="Select Form2"></p-dropdown>
            </span>
            <div class="p-field p-col-10 p-md-5">
                <button class="element" pButton type="submit" label="Submit" [disabled]="!fBranch.valid"></button>
            </div>
        </div>
    </form>
</div>