import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { FormService } from 'src/app/core/services/form.service';
import { BranchService } from 'src/app/core/services/branch.service';
import { UserService } from 'src/app/core/services/user.service';
import { DatePipe } from '@angular/common';
import { UploadService } from 'src/app/core/services/upload.service';
import { AuthService } from 'src/app/core/services/auth.service';


interface Branches {
  name: string;
  code: string;
  id: number;
}
@Component({
  selector: 'app-patient',
  templateUrl: './therapist.component.html',
  styleUrls: ['./therapist.component.scss']
})
export class TherapistComponent implements OnInit {
  formid = undefined
  editComment = false
  displayComment = false
  comment = ''
  public forms;
  public Forms;
  public date;
  public date1;
  public branch;
  public branches: SelectItem[];
  public selectBranch: Branches;
  public branchid;
  public formFetching;
  first = 0;
  rows = 10;
  therapists: any;
  edit = false
  userid: any
  formDetails: any
  constructor(private formService: FormService,
    private datePipe: DatePipe,
    private userService: UserService,
    private authservice: AuthService,
    private messageService: MessageService,
    private branchService: BranchService,
    private uploadService: UploadService) {
    this.forms = [];
    this.branches = [];
    this.date = new Date();
    this.formFetching = false;
  }

  async ngOnInit() {
    this.userid = await this.authservice.userId()
    this.therapists = await this.userService.getphisiolist()
    this.therapists = [...this.therapists[0]]

  }

  async onSubmit() {
    this.formFetching = true;
    this.date = this.datePipe.transform(this.date, "yyyy-MM-dd");
    console.log(this.date);
    console.log(this.userid);

    this.formDetails = await this.formService.getformfortherapist(this.date, this.userid);
    console.log(this.formDetails);

    this.formDetails = [...this.formDetails]
    console.log(this.formDetails);
    this.fetchForms(this.formDetails)
    this.formFetching = false;
    //this.date = undefined
  }
  async fetchForms(formValue) {

    let tempForms = [];
    for (let i = 0; i < formValue.length; i++) {
      const element = formValue[i];

      //Get Branch Name
      const br: any = await this.branchService.getBranchByBranchId(element.branchid);
      element.branchName = br.name;

      //Get Uploaded forms
      const uploadedForms = await this.formService.getFormData(element.formid);
      element.uploadedForms = uploadedForms;
      tempForms.push(element);
    }

    this.forms = tempForms;
    this.formFetching = false;
    console.log(this.forms);

  }
  async assign(event: any, formid: any) {
    console.log(event.value.userid);
    const data = {
      userid: event.value.userid,
      formid: formid
    }
    const success = await this.formService.addtherapist(data)
    if (success == "ok") {
      this.onSubmit()
      return this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Therapist Assigned !' });
    }
    return this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Therapist Not Assigned !' });

  }
  showComment(comment, formid) {
    this.formid = formid
    this.editComment = false
    this.displayComment = true
    this.comment = comment
  }
  addComment(comment, formid) {
    this.formid = formid
    this.displayComment = true
    this.editComment = true
    this.comment = comment

  }
  close() {
    this.editComment = false
    this.displayComment = false
  }
  editc() {
    this.editComment = true
  }
  async updateComment() {
    let data = {
      formid: this.formid,
      comment: this.comment
    }
    const res = await this.formService.addComment(data).catch(err => {
      console.log(err);
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Comment Not Updated!!!' });

    })
    if (res == 'ok') {
      this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Comment Updated!!!' });
      this.displayComment = false
      this.onSubmit()
    }
  }
  async onRowEditSave(form) {
    this.edit = false
    const body = {
      updates: {
        name: form.name,
        dob: this.datePipe.transform(form.dob, "yyyy-MM-dd"),
        tokenNo: form.tokenNo
      },
      formid: form.formid
    }

    await this.formService.updateForms(body);
    this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Form Updated!!!' });
  }
  editbtn() {
    this.edit = true

  }
  canceledit() {
    this.edit = false
  }
  async onDownload(uploadedForm) {
    const url = await this.uploadService.getLinkForFile(uploadedForm.path);
    window.location.href = url;
  }

}

