<div class="p-grid p-justify-center p-component">
  <div class="p-col-12 p-md-6 p-lg-6 p-xl-6 p-justify-center">
    <a class="active-dashboard" (click)="menuClicked('dashboard')" routerLink="dashboard/admindashboard"><i
        class="fa fa-home icon"></i>{{ 'MENU.DASHBOARD' | translate }}</a>
    <a class="active-patient" (click)="menuClicked('patient')" routerLink="patient/patient-form"><i
        class="fa fa-check-square-o icon"></i>{{ 'MENU.PATIENT' | translate }}</a>
    <a class="active-mainadmin" (click)="menuClicked('mainadmin')" routerLink="mainadmin/branch-userlist"><i
        class="fa fa-user-plus icon"></i>{{ 'MENU.MAINADMIN' | translate }}</a>
    <a class="active-logout" (click)="logout()" ><i
        class="fa fa-sign-out icon"></i>Logout</a>
    <a class="active-name">{{this.userName}}</a>
  </div>
</div>
<div>
  <router-outlet></router-outlet>
</div>