import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { servername } from '../strings';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(private http: HttpClient,
    private auth: AuthService) { }

  getUrlForNewObject(cont_type) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/s3/new?type=${cont_type}`, { headers: headers }).toPromise();
  }

  getUrlForView(key) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/s3/get?key=${key}`, { headers: headers }).toPromise();
  }
}
