<div class="p-component">
    <div class="p-grid p-justify-center">
        <div class="p-col-10 p-sm-8 p-md-8 p-lg-3 p-xl-2">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Date</span>
                <p-calendar #d required [(ngModel)]="date" name="date" [showIcon]="true" inputId="icon" [style]="{'height':'100%'}"></p-calendar>
            </div>
        </div>
        <div class="p-field p-col-10 p-sm-8 p-md-8 p-lg-3 p-xl-2">
            <button pButton type="submit" label="Submit" (click)="onSubmit()"></button>
        </div>
        <div *ngIf="formFetching === true">
            <p-progressSpinner class="spinner" strokeWidth="4" fill="#EEEEEE">
            </p-progressSpinner>
        </div>
    </div>

    <div class="table">
        <p-table [value]="formDetails" dataKey="frmdispid" editMode="row" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr styleClass="color">
                    <th>Form ID</th>
                    <th>Branch Name</th>
                    <th style="width:13rem">Name</th>
                    <th style="width:12rem">Date of Birth</th>
                    <th>Random No</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Forms</th>
                    <th>Comment</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-frm let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="frm">
                    <td>{{frm.frmdispid}}</td>
                    <td>{{frm.branchName}}</td>
                    <td style="width:10rem">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="frm.name">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <div>{{frm.name}}</div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="width:12rem">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="date" [(ngModel)]="frm.dob">
                                <!-- <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">Date</span>
                                    <p-calendar required [(ngModel)]="frm.dob" name="dob" [showIcon]="true" inputId="icon" [style]="{'height':'100%'}"></p-calendar>
                                </div> -->

                                <!-- <p-calendar [(ngModel)]="frm.dob" name="cal" [showIcon]="true" [style]="{'height':'100%'}"></p-calendar> -->

                            </ng-template>
                            <ng-template pTemplate="output">
                                <div>{{frm.dob| date:"dd-MM-yyyy" }}</div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>{{frm.tokenNo}}</td>
                    <td>{{frm.cat| date:"dd-MM-yyyy"}}</td>
                    <td>{{frm.cat| date:'hh:mm a'}}</td>

                    <td>
                        <span *ngFor="let uploadedForm of frm.uploadedForms">
              <button pButton pRipple type="button" pTooltip="{{uploadedForm.name}}" tooltipPosition="bottom"
                pSaveEditableRow icon="pi pi-arrow-down" (click)="onDownload(uploadedForm)"
                class="p-button-rounded p-button-text"></button>
            </span>
                    </td>
                    <!-- <td class="alignment">
            <button *ngIf="!editing" pButton pRipple type="button" pTooltip="Edit" tooltipPosition="bottom"
              pInitEditableRow icon="pi pi-pencil" (click)="editbtn()" class="p-button-rounded p-button-text"></button>
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fa fa-tint" (click)="onRowEditInit(user)" class="p-button-rounded p-button-text"></button>
            <button *ngIf="editing" pButton pRipple type="button" pTooltip="Save" tooltipPosition="bottom"
              pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(frm)"
              class="p-button-rounded p-button-text p-button-success"></button>
            <button *ngIf="editing" pButton pRipple (click)="canceledit()" type="button" pTooltip="Cancel" tooltipPosition="bottom"
              pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
          </td> -->
                    <td>
                        <span *ngIf="(frm.comment==''||frm.comment==null||frm.comment==undefined) ;else elseb"> <a class="addcomment" (click)="addComment(frm.comment,frm.formid)" >Add</a> </span>
                        <ng-template #elseb>
                            <i (click)="showComment(frm.comment,frm.formid)" class=" edit pi pi-eye"></i>
                        </ng-template>
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
            <ng-template pTemplate="paginatorright">
                <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog header="comment" [(visible)]="displayComment">
    <div class="comment">
        <div *ngIf='editComment==false'>
            <p>{{comment}}</p><span class="edit" (click)="editc()">  <button class="edits"  pButton   label="EDIT"></button></span>

        </div>
        <div *ngIf='editComment==true'>
            <textarea class="commenttextarea" pInputTextarea [(ngModel)]="comment"></textarea>
            <button pButton (click)="updateComment()" label="UPDATE"></button> <button (click)="close()" class="p-button-secondary" pButton label="CANCEL"></button>

        </div>
    </div>
</p-dialog>