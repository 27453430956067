import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from "@angular/core";

import { AppRoutingModule } from './app-routing.module';
import { HttpErrorResponse } from "@angular/common/http";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { StaffModule } from './staff/staff.module';
import { SharedModule } from './shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import * as Sentry from "@sentry/browser";
import { SpinnerModule } from 'primeng';

Sentry.init({
  dsn: "https://1a95ad4cf13b4c31b2e019a08eb7e515@o272411.ingest.sentry.io/5308952",
  // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
  // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
  // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
  integrations: [new Sentry.Integrations.TryCatch({
    XMLHttpRequest: false,
  })],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === "string" || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    let extractedError = this.extractError(error) || "Handled unknown error";

    // Capture handled exception and send it to Sentry.
    if (environment.production) {
      const eventId = Sentry.captureException(extractedError);
    } else {
      console.error(extractedError);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AuthModule,
    AdminModule,
    StaffModule,
    ToastModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    PaginatorModule,
    SpinnerModule,
    AppRoutingModule, //this must be called last
  ],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent],
})
export class AppModule { }