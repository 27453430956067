import { BranchService } from './../../../core/services/branch.service';
import { Component, OnInit } from '@angular/core';
import { UserService, User } from 'src/app/core/services/user.service';
import { MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormService } from 'src/app/core/services/form.service';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-branch-userlist',
  templateUrl: './branch-userlist.component.html',
  styleUrls: ['./branch-userlist.component.scss']
})
export class BranchUserlistComponent implements OnInit {

  public branches;
  public users;
  public usertype;
  public types;
  public displaytype;
  public state;
  public typeid;
  disabled: boolean = true;
  phisiolist:any
  constructor(private branchService: BranchService,
    private userService: UserService,
    private messageService: MessageService,
    private formService: FormService,
    private router: Router) {

    this.branches = [];
    this.users = [];
    this.types = [];
    this.state = [
      { label: 'Suspended', value: { id: 0, name: 'Suspended', code: 'S' } },
      { label: 'Active', value: { id: 1, name: 'Active', code: 'A' } }
    ]
  }

  async ngOnInit() {
    this.branches = await this.branchService.getBranch();
    this.processBranches(this.branches);
    this.usertype = await this.userService.userTypes();
    this.processUsertypes(this.usertype);
    this.users = await this.userService.getUser();
    this.processUsers(this.users);
    //this.formName = await this.formService.getBranchForms();
    //this.processForms(this.formName);
    // console.log(this.users)
    // console.log(this.usertype);


  }

  async processBranches(branches) {
    for (let i = 0; i < branches.length; i++) {
      let branch = branches[i];
      branch.forms = [];
      const branchForms: any = await this.branchService.getBranchFormsByBranchId(branch.branchid);
      for (let i = 0; i < branchForms.length; i++) {
        const form = branchForms[i];
        const formData: any = await this.formService.getFormsByFormId(form.formid);
        branch.forms.push(formData.name);
      }
    }
  }

  processUsertypes(usertype) {
    for (let i = 0; i < usertype.length; i++) {
      const element = usertype[i];
      this.types.push({
        label: element.displaytype,
        value: {
          id: element.typeid,
          name: element.type
        }
      })
    }
  }

  getTypeName(typeid) {
    console.log(typeid);

    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].value.id === typeid)
        return this.types[i].label;
    }
  }

  getType(typeid) {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].value.id === typeid)
        return this.types[i];
    }
  }

  async processUsers(users) {
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      element.selState = this.state[element.state].value;
      element.typeName = this.getTypeName(element.typeid);
      const branch: any = await this.branchService.getBranchByBranchId(element.branchid);
      element.branch = branch.name;
      element.selType = this.getType(element.typeid).value;
    }
  }

  async onRowEditSave(branch) {
    const body = {
      updates: {
        name: branch.name,
        address: branch.address,
        zip: branch.zip,
        contactno: branch.contactno,
        brid: branch.brid,
        branchprefix: branch.branchprefix,
      },
      branchid: branch.branchid
    }
    await this.branchService.branchUpdate(body);
    this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Branch Updated!!!' });

  }

  async onRowEditUserSave(user) {
    const body = {
      fname: user.fname,
      lname: user.lname,
      typeid: user.selType.id,
      emailid: user.emailid,
      phone: user.phone,
      state: user.selState.id
    }
    await this.userService.userUpdate(user.userid, body);
    user.state = user.selState.id;
    user.typeName = this.getTypeName(user.selType.id);
    this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'User Updated!!!' });
  }

  // async onClickActive(user) {
  //     await this.userService.activateUser(user.userid);
  //     user.state = 1;
  // }

  // async onClickSuspend(user) {
  //     await this.userService.suspendUser(user.userid);
  //     user.state = 0;
  //   }

  onChangePassword(user) {
    if (user.username === 'admin') {
      this.router.navigateByUrl('admin/mainadmin/change-password')
    }
    else {
      this.router.navigateByUrl('admin/mainadmin/change-password-user/' + user.userid + '/' + user.username)
    }
  }


}
