import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  DashboardComponent, PatientComponent, ChangepasswordComponent
} from './pages';

import { StaffdashboardComponent } from './dashboard_comps';
import { StaffchangepasswordComponent } from './changepassword_comps';
import { StaffpatientFormComponent } from './patient_comps';
import { StaffComponent } from './pages/staff.component';
import { LoginGuard } from '../core/guards/login-guard';
import { StaffAdminGuard } from '../core/guards/staffadmin-guard';
import { TherapistComponent } from './pages/therapist/therapist.component';

const routes: Routes = [
  {
    path: 'staff', component: StaffComponent, canActivate: [LoginGuard, StaffAdminGuard],
      children: [
        {
          path: 'dashboard', component: DashboardComponent,
          children: [
            { path: 'staffdashboard', component: StaffdashboardComponent }
          ]
        },
        {
          path: 'patient', component: PatientComponent,
          children: [
            { path: 'staffpatient-form', component: StaffpatientFormComponent }
          ]
        },  {
          path: 'patient', component: PatientComponent,
          children: [
            { path: 'therapist-form', component: TherapistComponent }
          ]
        },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    LoginGuard,
    StaffAdminGuard
  ]
})
export class StaffRoutingModule { }
