<div class="p-component">
    <div class="p-grid p-justify-center">
        <div class="p-col-10 p-sm-8 p-md-8 p-lg-3 p-xl-2">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Date</span>
                <p-calendar required [(ngModel)]="date" name="date" [showIcon]="true" inputId="icon" [style]="{'height':'100%'}"></p-calendar>
            </div>
        </div>
        <div class="p-field p-col-10 p-sm-8 p-md-8 p-lg-3 p-xl-2">
            <button pButton type="submit" label="Submit" (click)="onSubmit()"></button>
        </div>
        <div *ngIf="formFetching === true">
            <p-progressSpinner class="spinner" strokeWidth="4" fill="#EEEEEE">
            </p-progressSpinner>
        </div>
    </div>

    <div class="table">
        <p-table [value]="forms" dataKey="frmdispid" editMode="row" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr styleClass="color">
                    <th>Form ID</th>
                    <th>Branch Name</th>
                    <th style="width:13rem">Name</th>
                    <th style="width:12rem">Date of Birth</th>
                    <th>Random No</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Therapist</th>
                    <th>Forms</th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-frm let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="frm">
                    <td>{{frm.frmdispid}}</td>
                    <td>{{frm.branchName}}</td>
                    <td style="width:10rem">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="frm.name">
                            </ng-template>
                            <ng-template pTemplate="output">
                                <div>{{frm.name}}</div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="width:12rem">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="date" [(ngModel)]="frm.dob">

                            </ng-template>
                            <ng-template pTemplate="output">
                                <div>{{frm.dob| date:"dd-MM-yyyy" }}</div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>{{frm.tokenNo}}</td>
                    <td>{{frm.cat| date:"dd-MM-yyyy"}}</td>
                    <td>{{frm.cat| date:'hh:mm a'}}</td>

                    <td>
                        <span *ngIf="frm.uploadedForms[0].name=='FormMassage'">
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                                <select (change)="assign($event,frm.formid, frm)" pInputText>
                                    <option selected disabled>Select </option>
                                    <option  *ngFor="let item of therapists" value="{{item.userid}}">{{item.fname}}</option>
                                    </select>
                                <!-- <p-dropdown (onChange)="assign($event,frm.formid) " [options]="therapists" optionLabel="fname" placeholder="Assign To ">
                                </p-dropdown> -->
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngFor="let obj of allTherapists ">
                                <span *ngIf="obj.userid==frm.therapist ">{{obj.fname+" "+obj.lname}}</span></span>
            </ng-template>
            </p-cellEditor>
            </span>
            </td>
            <td>
                <span *ngFor="let uploadedForm of frm.uploadedForms">
              <button pButton pRipple type="button" pTooltip="{{uploadedForm.name}}" tooltipPosition="bottom"
                pSaveEditableRow icon="pi pi-arrow-down" (click)="onDownload(uploadedForm)"
                class="p-button-rounded p-button-text"></button>
            </span>
            </td>
            <td class="alignment">
                <button *ngIf="!editing" pButton pRipple type="button" pTooltip="Edit" tooltipPosition="bottom" pInitEditableRow icon="pi pi-pencil" (click)="editbtn(frm)" class="p-button-rounded p-button-text"></button>
                <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fa fa-tint" (click)="onRowEditInit(user)" class="p-button-rounded p-button-text"></button> -->
                <button *ngIf="editing" pButton pRipple type="button" pTooltip="Save" tooltipPosition="bottom" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(frm)" class="p-button-rounded p-button-text p-button-success"></button>
                <button id="cancel" *ngIf="editing" pButton pRipple (click)="canceledit()" type="button" pTooltip="Cancel" tooltipPosition="bottom" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
            </td>

            </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
            <ng-template pTemplate="paginatorright">
                <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-table>
    </div>
</div>