<!-- <div class="item-main-page">
    <div class="item-main-page__menu">
        <div class="p-field p-grid p-justify-start">
            <div class="item-main-page__chart p-col-fixed">
                <a class="menu-item" routerLink="patient-chart">
                    {{ 'DASHBOARD.CHART' | translate }}
                    <i class="menu-item__icon fa fa-list-alt"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="item-main-page__page"> -->
         <router-outlet></router-outlet>
  <!--  </div>
</div> -->