<div class="item-main-page">
    <div class="item-main-page__menu">
            <div class="item-main-page__branch p-col-fixed">
                <div class="menu-item p-component" routerLink="create-branch">
                    {{ 'MAINADMIN.BRANCH' | translate }}
                    <i class="menu-item__icon fa fa-code-fork"></i>
                </div>
            </div>
            <div class="item-main-page__user p-col-fixed">
                <div class="menu-item p-component" routerLink="create-user">
                    {{ 'MAINADMIN.USER' | translate }}
                    <i class="menu-item__icon fa fa-user"></i>
                </div>
            </div>
    </div>
    <div class="item-main-page__page">
        <router-outlet></router-outlet>
    </div>
</div>