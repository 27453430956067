import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class StaffAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isStaff())
      return true;
    if (this.authService.isTherapist())
      return true;
    this.router.navigateByUrl('/login');
    return false;
  }
}
