import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { servername } from '../strings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private auth: AuthService) { }

  public async createUser(formvalues: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    const result1: any = await this.http.post(`${servername}/user`, { ...formvalues }, { headers: headers }).toPromise();
  }

  public async getUser() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/user`, { headers: headers }).toPromise();
  }

  public async userTypes() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/usertype`, { headers: headers }).toPromise();
  }

  public async userByTypeId(typeid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/usertype?typeid=${typeid}`, { headers: headers }).toPromise();
  }

  public async getUserByUserId(userid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/user?userid=${userid}`, { headers: headers }).toPromise();
  }

  public async getTypeByTypeId(typeid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/usertype?id=${typeid}`, { headers: headers }).toPromise();
  }

  public async changePassword(oldpw, newpw) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user/changePassword`, { oldpw, newpw }, { headers: headers }).toPromise();
  }

  public async changePasswordByAdmin(userid, newpw) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user/changePWbyadmin?userid=${userid}`, { newpw }, { headers: headers }).toPromise();
  }

  public async userUpdate(userid, body) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user?userid=${userid}`, { ...body }, { headers: headers }).toPromise();
  }

  public async activateUser(userid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user/activate?userid=${userid}`, { headers: headers }).toPromise();
  }

  public async suspendUser(userid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user/suspend?userid=${userid}`, { headers: headers }).toPromise();
  }
  public async getphisiolist() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/user/phisiolist`, { headers: headers }).toPromise();
  }
}

export interface User {
  fname: string;
  lname: string;
  phone: string;
  typeid: number;
  userid: number;
}
