import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StaffRoutingModule } from './staff.routing.module';
import { SharedModule } from '../shared/shared.module';

import {
  DashboardComponent, PatientComponent, ChangepasswordComponent
} from './pages';

//Primeng Modules
import { ChartModule } from 'primeng/chart';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { PasswordModule } from 'primeng/password';
import { TooltipModule } from 'primeng/tooltip';

import { StaffchangepasswordComponent } from './changepassword_comps';
import { StaffdashboardComponent } from './dashboard_comps';
import { StaffpatientFormComponent } from './patient_comps';
import { StaffComponent } from './pages/staff.component';

import { UploadService } from '../core/services/upload.service';
import { ReportService } from '../core/services/report.service';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { TherapistComponent } from './pages/therapist/therapist.component';
import {DialogModule} from 'primeng/dialog';

// AoT requires an exported function for factories
//This is needed for internationalization
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    DashboardComponent,
    PatientComponent,
    ChangepasswordComponent,
    StaffComponent,
    TherapistComponent,
    StaffchangepasswordComponent,
    StaffdashboardComponent,
    StaffpatientFormComponent
  ],
  imports: [
    StaffRoutingModule,
    SharedModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    DialogModule,
    ChartModule,
    FileUploadModule,
    BrowserModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    ChartModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    BrowserModule,
    TableModule,
    CalendarModule,
    PasswordModule,
    TooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // AngularEditorModule,
  ],
  providers: [
    DatePipe,
    UploadService,
    ReportService,
    MessageService,
  ]
})
export class StaffModule { }
