import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { SelectItem, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

interface Types {
  name: string;
  code: string;
  id: number;
}

@Component({
  selector: 'app-change-password-user',
  templateUrl: './change-password-user.component.html',
  styleUrls: ['./change-password-user.component.scss']
})
export class ChangePasswordUserComponent implements OnInit {

  public usertype;
  public types: SelectItem[];
  public selectType: Types;
  public newpw;
  public userid;
  public username;
  public confirmpw;

  constructor(private userService: UserService,
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router) {
    this.route.params.subscribe(params => {
      this.userid = params.userid
      this.username = params.username;
    });
    this.types = [];
  }

  async ngOnInit() {
  }

  async onClick() {
    if (this.newpw === this.confirmpw) {
      await this.userService.changePasswordByAdmin( this.userid, this.newpw);
      this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Password successfully changed!' });
        this.router.navigateByUrl('admin/mainadmin/branch-userlist')
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Password Mismatched!!!' });
    }
  }
}
