import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin.routing.module';
import { SharedModule } from '../shared/shared.module';
import {FileUploadModule} from 'primeng/fileupload';

//primeng modules
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { PasswordModule } from 'primeng/password';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';

import {
  DashboardComponent, AdminComponent, PatientComponent, MainadminComponent
} from './pages';

import { CreateUserComponent, CreateBranchComponent, BranchUserlistComponent, ChangePasswordComponent, ChangePasswordUserComponent } from './admin_comps';
import { PatientFormComponent } from './patient_comps';
import { PatientChartComponent } from './dashboard_comps';


import { UploadService } from '../core/services/upload.service';
import { ReportService } from '../core/services/report.service';
import { MessageService } from 'primeng/api';
import { BranchService } from '../core/services/branch.service';
import { UserService } from '../core/services/user.service';
import { FormService } from './../core/services/form.service';

// import { UsertypeService} from '../core/services/usertype.service';
// AoT requires an exported function for factories
//This is needed for internationalization
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    PatientComponent,
    MainadminComponent,
    CreateUserComponent,
    CreateBranchComponent,
    BranchUserlistComponent,
    ChangePasswordUserComponent,
    ChangePasswordComponent,
    PatientFormComponent,
    PatientChartComponent
  ],
  imports: [
    AdminRoutingModule,
    SharedModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ChartModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    BrowserModule,
    TableModule,
    CalendarModule,
    PasswordModule,
    TooltipModule,
    PaginatorModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    DatePipe,
    UploadService,
    ReportService,
    MessageService,
    BranchService,
    UserService,
    FormService,
    DatePipe
    // UsertypeService
  ]
})
export class AdminModule { }
