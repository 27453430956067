<div class="p-grid p-justify-center p-component">
    <div class="p-col-12 p-md-6 p-lg-6 p-xl-6 p-justify-center">
        <a *ngIf="!therapist" class="active-dashboard" (click)="menuClicked('dashboard')" routerLink="dashboard/staffdashboard"><i
        class="fa fa-home icon"></i>{{ 'MENU.DASHBOARD' | translate }}</a>
        <a *ngIf="!therapist" class="active-patient" (click)="menuClicked('patient')" routerLink="patient/staffpatient-form"><i
        class="fa fa-check-square-o icon">&nbsp;&nbsp;</i>{{ 'MENU.PATIENT' | translate }}</a>
        <a class="active-logout" (click)="logout()"><i class="fa fa-sign-out icon">&nbsp;&nbsp;</i>Logout</a>
        <a class="active-name">{{this.userName}} </a>
    </div>
</div>
<div class="staff-page__page">
    <router-outlet></router-outlet>
</div>