<div class="container">
    <div class="center-login">
        <div class="p-grid">
            <div class="p-col-12 p-component title">
                {{ 'LOGIN.TITLE' | translate }}
            </div>
        </div>
        <div class="p-grid">
            <form #flogin="ngForm" (ngSubmit)="onSubmit(flogin.value)">
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-user"></i>
                        <input #loginid name="username" ngModel required type="text" class="p-inputtext-lg" pInputText
                            placeholder="Username">
                    </span>
                </div>
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-lock"></i>
                        <input #password name="password" ngModel required type="password" class="p-inputtext-lg"
                            pPassword placeholder="Password">
                    </span>
                </div>
                <div *ngIf="submitted === false">
                    <button pButton type="submit" label="{{ 'LOGIN.LOGIN' | translate}}"
                        [disabled]="!flogin.valid"></button>
                </div>
            </form>
        </div>
    </div>
</div>