<div class="table">
    <p-table [value]="branches" dataKey="brid" editMode="row" [scrollable]="false" [autoLayout]="true">
        <ng-template pTemplate="caption">
            <div class="table-header">Branch List</div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Br Id</th>
                <th>Branch Prefix</th>
                <th>Branch Name</th>
                <th>Forms</th>
                <th>Address</th>
                <th>Contact No</th>
                <th>Zip Code</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-branch let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="branch">
                <td>
                    <div class="alignment">{{branch.brid}}</div>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="branch.branchprefix">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{branch.branchprefix}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="branch.name">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{branch.name}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <div *ngFor="let form of branch.forms">
                        <span>{{form}}</span>
                    </div>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="branch.address">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{branch.address}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="branch.contactno">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{branch.contactno}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="number" [(ngModel)]="branch.zip">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{branch.zip}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="aligment">
                    <button *ngIf="!editing" pButton pRipple type="button" pTooltip="Edit" tooltipPosition="bottom"
                        pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>
                    <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fa fa-tint" (click)="onRowEditInit(branch)" class="p-button-rounded p-button-text"></button> -->
                    <button *ngIf="editing" pButton pRipple type="button" pTooltip="Save" tooltipPosition="bottom"
                        pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(branch)"
                        class="p-button-rounded p-button-text p-button-success"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pTooltip="Cancel" tooltipPosition="bottom"
                        pCancelEditableRow icon="pi pi-times"
                        class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="table">
    <p-table [value]="users" dataKey="userid" editMode="row" [autoLayout]="true">
        <ng-template pTemplate="caption">
            <div class="table-header">User List</div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>User Name</th>
                <th>Role</th>
                <th>Email Id</th>
                <th>Contact No</th>
                <th>Branch</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="user">
                <td>
                    <div class="alignment">{{user.userid}}</div>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="alignment" pInputText type="text" [(ngModel)]="user.fname">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{user.fname}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="alignment" pInputText type="text" [(ngModel)]="user.lname">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{user.lname}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <div class="alignment">{{user.username}}</div>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown class="alignment" [options]="types" [(ngModel)]="user.selType">
                            </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{user.typeName}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="alignment" pInputText type="text" [(ngModel)]="user.emailid">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{user.emailid}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="alignment" pInputText type="text" [(ngModel)]="user.phone">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment">{{user.phone}}</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <div class="alignment">{{user.branch}}</div>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown class="alignment" [options]="state" [(ngModel)]="user.selState"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div class="alignment" *ngIf='user.state === 0'>Suspended</div>
                            <div class="alignment" *ngIf='user.state === 1'>Active</div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td class="alignment">
                    <button *ngIf="!editing" pButton pRipple type="button" pTooltip="Edit" tooltipPosition="bottom"
                        pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>
                    <i *ngIf="!editing" pButton pRipple type="button" pTooltip="Change Password"
                        tooltipPosition="bottom" pInitEditableRow icon="pi pi-lock" (click)="onChangePassword(user)"
                        class="p-button-rounded p-button-text"></i>
                    <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fa fa-tint" (click)="onRowEditInit(user)" class="p-button-rounded p-button-text"></button> -->
                    <button *ngIf="editing" pButton pRipple type="button" pTooltip="Save" tooltipPosition="bottom"
                        pSaveEditableRow icon="pi pi-check" (click)="onRowEditUserSave(user)"
                        class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pTooltip="Cancel" tooltipPosition="bottom"
                        pCancelEditableRow icon="pi pi-times"
                        class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>