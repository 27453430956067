import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { servername } from '../strings';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient,
    private auth: AuthService) { }

  public async getForms() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/form`, { headers: headers }).toPromise();
  }

  public async getBranchForms() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/formlist`, { headers: headers }).toPromise();
  }

  public async getFormsByFormId(formid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/formlist?formid=${formid}`, { headers: headers }).toPromise();
  }

  public async getFormsByDate(date, branchid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/form?date=${date}&branchid=${branchid}`, { headers: headers }).toPromise();
  }

  public async getPatientFormsByDate(date1, date2) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/form?date1=${date1}&date2=${date2}`, { headers: headers }).toPromise();
  }

  public async getFormsOnlyByDate(date) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/form?date=${date}`, { headers: headers }).toPromise();
  }

  public async updateForms(body) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/form`, { ...body }, { headers: headers }).toPromise();
  }

  public async getFormData(formid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/formdata?formid=${formid}`, { headers: headers }).toPromise();
  }

  public async addtherapist(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/user/linkphisio`, data, { headers: headers }).toPromise();
  }
  public async getformfortherapist(date,id) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/form/therapist?date=${date}&id=${id}`,  { headers: headers }).toPromise();
  }

  public async addComment(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/form/comment`, data, { headers: headers }).toPromise();
  }
}
