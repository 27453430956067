import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public newpw;
  public oldpw;
  public password;
  public usertype;
  public types;

  constructor(private userService: UserService,
    private messageService: MessageService,
    private router: Router) {
    this.types = [];
  }

  async ngOnInit() {
    this.usertype = await this.userService.userTypes();
    this.processUserTypes(this.usertype);
  }

  processUserTypes(usertype) {
    for (let i = 0; i < usertype.length; i++) {
      const element = usertype[i];
      this.types.push({
        label: element.type,
        value: {
          id: element.typeid,
          name: element.type
        }
      })
    }
  }

  async onSubmit(formvalues) {
    if (this.newpw == this.password) {
      try {
        await this.userService.changePassword(this.oldpw, this.newpw);
        this.messageService.add({ severity: 'success', summary: 'PeaceRiver', detail: 'Password successfully changed!' });
        this.router.navigateByUrl('admin/mainadmin/branch-userlist')
      } catch {
        this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Incorrect old password!' });
      }
      
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'PeaceRiver', detail: 'Confirm Password Mismatch!' });
    }
  }

}
