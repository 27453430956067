<div class="changepw p-component p-col-10 p-md-5 p-lg-8 p-xl-8">
    <div class="heading">
        Change User Password for {{this.username}}
    </div>
    <div class="p-component p-fluid p-formgrid p-grid p-col-10">
        <span class="p-float-label p-field p-col-10 p-md-6">
            <input pPassword required [(ngModel)]="newpw" id="pw" name="newpw" type="password" pInputText>
            <label for="pw">New Password</label>
        </span>
        <span class="p-float-label p-field p-col-10 p-md-6">
            <input pPassword required [(ngModel)]="confirmpw" id="npw" name="confirmpw" type="password" pInputText>
            <label for="npw">Confirm Password</label>
        </span>
    </div>
    <span class="p-float-label p-field p-col-10 p-md-6">
        <button pButton type="submit" label="Reset Password" (click)="onClick()"></button>
    </span>
</div>