import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  public userData;
  public userName;

  private selectedMenu: string = "dashboard";

  constructor(public translate: TranslateService,
    public authService: AuthService,
    private userService: UserService,
    private routes: Router) { }
  therapist: boolean
  async ngOnInit() {
    this.therapist = this.authService.isTherapist()
    console.log(this.therapist);

    document.body.classList.add('bg-img');
    this.userData = await this.userService.getUserByUserId(this.authService.userId())
    this.userName = this.userData.fname + ' ' + this.userData.lname
  }

  isActive(menuStr: string): string {
    if (menuStr === this.selectedMenu)
      return 'active';
    return '';
  }

  menuClicked(menuStr: string): void {
    this.selectedMenu = menuStr;
  }

  logout() {
    this.authService.logout();
    this.routes.navigateByUrl('');
  }

}
