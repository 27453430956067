export class ReqCache {

    public responseCache = new Map();

    cache(url, res) {
        this.responseCache.set(url, res);
        return res;
    }

    checkCache(url) {
        return this.responseCache.get(url);
    }

    //5 Mins
    shortCache(url, res) {
        this.responseCache.set(url, res);
        setTimeout(() => {
            this.responseCache.set(url, undefined);
        }, 5 * 60000);
        return res;
    }

    //15 Mins
    mediumCache(url, res) {
        this.responseCache.set(url, res);
        setTimeout(() => {
            this.responseCache.set(url, undefined);
        }, 15 * 60000);
        return res;
    }

    //30 Mins
    longCache(url, res) {
        this.responseCache.set(url, res);
        setTimeout(() => {
            this.responseCache.set(url, undefined);
        }, 30 * 60000);
        return res;
    }

    removeCache(url) {
        this.responseCache.set(url, undefined);
    }
}
