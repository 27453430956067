import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ReqCache } from './req-cache';
import { servername } from '../strings';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends ReqCache {

  constructor(private http: HttpClient,
    private auth: AuthService) {
      super();
  }

  public async createBranch(formvalues: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.post(`${servername}/branch`, { ...formvalues }, { headers: headers }).toPromise();
  }

  public async createBranchForms(formvalues: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.post(`${servername}/branchform`, { ...formvalues }, { headers: headers }).toPromise();
  }

  public async getBranchForms() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/branchform`, { headers: headers }).toPromise();
  }

  public async getBranchFormsByBranchId(branchid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/branchform?branchid=${branchid}`, { headers: headers }).toPromise();
  }

  public async getBranch() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.get(`${servername}/branch`, { headers: headers }).toPromise();
  }

  public async getBranchByBranchId(branchid) {
    const url=`${servername}/branch?branchid=${branchid}`;
    const cached = this.checkCache(url);
    if (!!cached) {
      return cached;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    // return this.http.get(`${servername}/branch?branchid=${branchid}`, { headers: headers }).toPromise();
    const res = this.http.get(url, { headers: headers }).toPromise();
    return this.cache(url,res);
  }

  public async branchUpdate(body) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.put(`${servername}/branch`, {...body}, { headers: headers }).toPromise();
  }

  public async deleteBranch(branchid) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', `Bearer ${this.auth.AuthToken}`);
    return this.http.delete(`${servername}/user/branch?branchid=${branchid}`, { headers: headers }).toPromise();
  }
}
