import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LocalNumberPipe } from './pipes/local-number.pipe';
import { AuthService } from '../core/services/auth.service';
import { DndDirective } from './directives/dnd.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileuploadGeneralComponent } from './fileupload-general/fileupload-general.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { ToastContainerComponent } from './toaster/toast-container/toast-container.component';
import { ToasterComponent } from './toaster/toaster/toaster.component';
import { GroupmemberComponent } from './groupmember/groupmember.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LocalDatePipe,
    LocalNumberPipe,
    DndDirective,
    FileUploadComponent,
    FileuploadGeneralComponent,
    TabHeaderComponent,
    ToastContainerComponent,
    ToasterComponent,
    GroupmemberComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LocalDatePipe,
    LocalNumberPipe,
    DndDirective,
    FileUploadComponent,
    FileuploadGeneralComponent,
    TabHeaderComponent,
    ToastContainerComponent,
    ToasterComponent,
    GroupmemberComponent
  ],
  providers: [
    AuthService
  ]
})
export class SharedModule { }

// Should not import in app module. Common components/ services/ pipes across the entire application can 
// be placed here. Only feature modules can import this module.